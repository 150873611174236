<template>
  <v-card  color="#001a4d" style="overflow:hidden !important" tile
    class="footer apply-background-color pa-0 px-2 mx-0" fluid>
    <v-container fill-height>
      <v-row class="pa-0" justify="center">
        <v-card flat color="#001a4d">
          <v-row justify="center">
            <v-avatar color="#ffffff"
              :size="$vuetify.breakpoint.smOnly ? 70 : 57">
              <v-img contain height="36" width="45" :lazy-src="require(`@/assets/${appName}-login-logo.png`)" :src="require(`@/assets/${appName}-login-logo.png`)"/>
            </v-avatar>
          </v-row>
          <v-col cols="12" sm="12" xs="6" class="mt-2">
            <span class="text-center white--text font-weight-bold display-1"> SYFol </span>
          </v-col>
          <v-col cols="12" xs="12" sm="12">
            <v-form ref="loginForm">
              <v-row justify="center">
                <v-col cols="12">
                  <v-text-field  outlined dense hide-details class="login-formfield" v-model="model.email" :rules="$_requiredValidation" autofocus dark>
                    <template #prepend-inner>
                      <v-icon color="white" class="login-formfield-icon mr-2 ml-n1" size="22"> mdi-account-circle </v-icon>
                      <v-divider vertical></v-divider>
                    </template>
                    <template #label>
                      <span class="body-2 px-2"> Username </span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details class="login-formfield my-2 my-xl-3" :type="showPassword ? 'text' : 'password'"
                    v-model="model.password" dark
                    :append-icon="model.password ? showPassword ? 'mdi-eye-off' : 'mdi-eye' : null"
                    :rules="$_requiredValidation"
                    @click:append.stop="showPassword = !showPassword"
                    @keyup.enter="normalUserLoginHandler">
                    <template #prepend-inner>
                      <v-icon class="login-formfield-icon mr-2 ml-0 mt-1" size="18" color="white"> mdi-lock </v-icon>
                      <v-divider vertical></v-divider>
                    </template>
                    <template #label>
                      <span class="body-2 px-2"> Password </span>
                    </template>
                  </v-text-field>
                  <div class="forgotpassword font-weight-medium cursor-pointer white--text caption" @click="$router.push('/forgotpassword')"> Forgot password? </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" xs="6" sm="12" class="pb-lg-0 pb-0 pb-xl-2 mr-3">
            <v-row justify="center">
              <v-col cols="12" sm="11" xs="6">
                <v-btn  block title='Sign in using Microsoft' :large="$vuetify.breakpoint.smOnly ? true : false"
                  :style="$vuetify.breakpoint.smOnly ? {fontSize:'1.3rem !important'}: ''"
                  class="text-primary rounded-xl text-capitalize font-weight-bold body-2 px-2"
                  color="white" @click="normalUserLoginHandler" :loading="loading">
                  <v-icon color="primary" class="mr-2"> mdi-arrow-right-circle  </v-icon>
                    Login
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="6" sm="12" class="pb-lg-0 pb-0 pb-xl-2 mr-3">
            <v-row justify="center">
              <v-col cols="12" sm="11" xs="6">
                <v-btn  block title='Sign in using Microsoft' :large="$vuetify.breakpoint.smOnly ? true : false"
                  :style="$vuetify.breakpoint.smOnly ? {fontSize:'1.3rem !important'}: ''"
                  class="text-primary rounded-xl text-capitalize font-weight-bold body-2 px-2"
                  color="white" @click="getSharepointUrl" :loading="sharepointloading">
                  <v-icon color="primary" class="mr-2"> mdi-microsoft </v-icon>
                    Sign in with Microsoft
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 my-2" xs="6" cols="12">
            <span class="white--text text-size text-size"
              :class="$vuetify.breakpoint.smOnly ? 'apply-text-size':''">Follow us
            </span>
          </v-col>
          <v-row class="mx-2  my-2" justify="center">
            <v-col cols="3" md="2"  sm="2" xl="2" xs="1" class="pa-0 pr-sm-1" lg="3"
              v-for="(item,index) in socialMedialLinks" :key="index">
              <v-chip small style="fontSize:11px"  class="ma-1"  target="_blank"
                :href="item.link" text-color="white" :style="{backgroundColor:item.backgroundColor}">
                <v-avatar class="ma-0  move-to-rightside" style="border:2px solid white !important">
                  <v-icon class="pa-1" color="white" style="font-size:15px;">{{ item.icon }}
                  </v-icon>
                </v-avatar>
                {{ item.text }}
              </v-chip>
            </v-col>
          </v-row>
          <v-col  class="pa-0 ma-2" xs="6" cols="12">
            <v-row  class="my-1" justify="center">
              <div class="footer mb-6 text-center white--text text-size mt-2"
                :class="$vuetify.breakpoint.smOnly ? 'apply-text-size':''"> &copy; {{ currentYear }},
                {{ appName === 'syfol' ? 'Processdrive India Pvt. Ltd. All Rights Reserved.' : 'Avalia AS All Rights Reserved' }}
              </div>
            </v-row>
          </v-col>
        </v-card>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { loadLanguageAsync } from '@/lang/config'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      sharepointloading: false,
      model: {
        domain: '',
        username: '',
        password: ''
      },
      language: [],
      defaultLang: '',
      snackbarAuth: false,
      text: 'text',
      color: 'success',
      showPassword: false,
      loggedInInfo: {},
      appName: process.env.VUE_APP_LOGO_ICONS_FOLDER,
      socialMedialLinks: [
        {
          text: 'Github',
          icon: 'mdi-github',
          color: 'black',
          link: this.appName === 'syfol' ? 'https://github.com/processdrive' : 'https://github.com/AvaliaAS',
          backgroundColor: '#757575'
        },
        {
          text: 'Twitter',
          icon: 'mdi-twitter',
          color: '#03A9F4',
          link: 'https://twitter.com/processdrive',
          backgroundColor: '#81D4FA'
        },
        {
          text: 'Linkedin',
          icon: 'mdi-linkedin',
          color: '#0D47A1',
          link: this.appName === 'syfol' ? 'https://www.linkedin.com/company/processdrive/' : 'https://www.linkedin.com/company/avalia-as',
          backgroundColor: '#1565C0'
        }]
    }
  },
  watch: {
    defaultLang (val) {
      this.$i18n.locale = this.defaultLang
      this.$cookie.set('Businessflow365PWA_locale', this.defaultLang)
      loadLanguageAsync({ lang: val, domain: this.model.domain })
    }
  },
  mounted () {
    // const domain = window.localStorage.getItem(process.env.VUE_APP_DOMAIN)
    if (this.$route.query.code) {
      const model = { code: this.$route.query.code }
      this.$router.replace({ path: this.$route.path, query: { code: '' } })
      this.$store.commit('startRouteLoader')
      this.loginActions(model)
    }
    // document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
    // elHtml.style.position = 'fixed !important'
    // if (domain && !this.$route.query.code) {
    //   this.model.domain = domain
    //   this.loadLanguage()
    // }
    // const domain = window.localStorage.getItem('businessflow365PWAdomain')
    // if (domain) {
    //   this.model.domain = domain
    //   this.loadLanguage()
    // }
  },
  destroyed () {
    // document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
  },
  computed: {
    ...mapGetters(['systemDetails']),
    currentYear () {
      return this.$formatter.getCurrentDate().split('.')[2]
    }
  },
  methods: {
    normalUserLoginHandler () {
      this.loginHandler(true)
    },
    loginHandler (isNormalUser) {
      if (this.$refs.loginForm.validate()) {
        if (isNormalUser) {
          const model = this.$formatter.cloneVariable(this.model)
          model.isNormalUserLogin = true
          this.loginActions(model, true)
        } else this.loginActions(this.model)
      } else {
        this.snackbarAuth = true
        this.color = 'error'
        this.text = 'Please check required fields!'
      }
    },
    loginActions (model, isNormalLogin) {
      if (!isNormalLogin) this.loading = true
      else this.normalUserLoader = true
      model.isSignupSharepoint = (window.localStorage.getItem(`${process.env.VUE_APP_NAME}_isLoginSharepoint`) && window.localStorage.getItem(`${process.env.VUE_APP_NAME}_isLoginSharepoint`)) === 'false'
      // localStorage.setItem('BFLOW_REMOVED_STRICT', true)
      this.loading = true
      this.$store.dispatch('login', model)
        .then((result) => {
          this.loggedInInfo = result
          if (result.statuscode) {
            switch (result.statuscode) {
              case 1:
                this.$store.commit('stopRouteLoader')
                this.snackbarAuth = true
                this.color = 'error'
                this.text = 'Your domain is not registered'
                break
              case 2 :
                this.$store.commit('stopRouteLoader')
                this.$router.push('/tenantsavailable')
                break
              case 3 :
                this.$store.commit('stopRouteLoader')
                this.$store.commit('multipleTenants', result)
                this.$router.push('/tenantsavailable')
            }
          } else {
            // this.$store.state.common.loadRouter = true
            /* get sharepoint settings */
            this.$store.dispatch('getModuleList')
            if (this.loggedInInfo.issharepointstorage) this.$store.dispatch('getSharepointFields')
            this.$router.push({ path: this.$route.query.redirect || '/dashboard' })
            this.$store.dispatch('systemNotification').then((response) => {
              this.$root.$emit('getSystemNotificationFromTenant', response)
            })
            this.$store.dispatch('getTimerSettings')
            this.getWorkspacesOfCurrentUser()
            this.$store.commit('stopRouteLoader')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSharepointUrl () {
      this.sharepointloading = true
      this.$api.execute('get', 'auth/get_sharepoint_auth_url?fromPwa=true').then(async response => {
        if (response.data) {
          window.open(response.data.url, '_self')
        }
      })
    },
    loadLanguage () {
      if (this.model.domain) {
        this.$api.execute('get', `languages/get_by_domain?domain=${this.model.domain}`).then(response => {
          this.language = response.data
          const hasDefault = this.language.find(x => x.isdefault)
          if (hasDefault) this.defaultLang = hasDefault.code
          window.localStorage.setItem(process.env.VUE_APP_DOMAIN, this.model.domain)
        })
      }
    }
  }
}
</script>
<style>
  .border-styles {
    border-top-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  /* .login .v-sheet--offset {
    top: -30px;
    position: relative;
  } */
  .apply-background-color {
    /* background-color:  #001a4d; */
    background-color: #001a4d;
    background-size: cover;
    overflow:auto;
    height: 100vh;
}
.footer {
   position: fixed !important;
   left: 0;
   bottom: 0;
   width: 100%;
   color: rgb(253, 253, 253);
   text-align: center;
}
.text-size {
  font-size:0.800rem !important;
}
.move-to-rightside
{
  position:relative !important;
  right:10px !important;
}
.container.container--app.pt-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}
.apply-text-size {
   font-size:1.3rem !important;
}
.login-input .v-input__control > .v-input__slot fieldset {

}
</style>
